@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
    text-decoration: none;
    color: #000000;
    border-bottom: 3px solid #3286ca;
    transition: 0.2s ease-out; 
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
  }

  .hoverable:hover {
      border-bottom-color: transparent;
  }

  .blue {
    border-bottom: 3px solid #3286ca;
  }

  .pink {
    border-bottom: 3px solid #da12bf;
  }

  .green {
    border-bottom: 3px solid #21ad78;
  }
  

.CollapsableSection_invisible__2-FOe {
    display: none;
}

.CollapsableSection_visible__20TEH {
    
}

.CollapsableSection_paragraph__1PpAF {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}

.CollapsableSection_link_container__2FisD {
    display: inline-block
}

.CollapsableSection_linkClickable__1kmt- {
    margin-top: 0px;
    margin-bottom: 0px;
    display: inline-block;
}
body, html {
  margin: 0;
  padding: 0;

}

p {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
}

.a-link {
  text-decoration: none;
  color: #000000;
  /* 
  blue #3286ca 
  pink #da12bf
  green #21ad78
  */
  border-bottom: 5px solid #3286ca;
  transition: 0.2s ease-out; 
}

.a-link:hover {
  border-bottom-color: transparent; 
}

a {
  text-decoration: none;
  color: #000000; 
  border-bottom: 5px solid #3286ca;
}

.App {
  text-align: center;
}

.padding {
  height: 5vh;
}

.container {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: rgb(1, 1, 1);
  display: flex;
  flex-direction: row;
}

.quoteBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  grid-gap: 1rem;
  gap: 1rem;
}

.quoteText {
  font-size: 0.5rem;
  font-style: italic;
}

@media screen and (min-width: 750px) {
  .left {
    /* background-color: #ffa7a1; */
    min-height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .name-email-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: 100vh;
  }
}

@media screen and (max-width: 750px) {
  .container {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: rgb(1, 1, 1);
    display: flex;
  }
  /* remove min width requirement in the media query */
  .left {
    /* background-color: #ffa7a1; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .name-email-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.name {
  font-size: 1.7rem;
}

.right {
  /* background-color: #a1d8ff; */
  min-height: 100vh;
  display: flex;
  flex: 3 1;

  padding-left: 2.5rem;
  padding-right: 2.5rem;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  text-align: left;
}

.profile {
  width: 15vw;
  object-fit: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.publication-container {
  width: 80%;  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  margin-bottom: 1em;
  
  width: 100%;
  text-align: left;
}

.publication-container p {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.indentedLi {
  margin-left: 1em;
  margin-bottom: 3px;
  list-style: none;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0rem;
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

hr {
  width: 90vw;
  height: 1px;
  border: 1;
  border-top: 1px solid #ccc;
  border-right: none;
  border-left: none;
  border-bottom: none;
  margin: 1em 0;
  padding: 0;
}

.text-footer {
  font-size: 1rem;
  padding-left: 5vw;
}

.publication_title {
  margin-top: 0.25rem;
}
.publication_ul {
  margin-bottom: 0px;
  margin-top: 0px;
}

.p_expandable_trigger {
  margin-top: 0px;
  margin-bottom: 0px;
}
