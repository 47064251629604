.invisible {
    display: none;
}

.visible {
    
}

.paragraph {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}

.link_container {
    display: inline-block
}

.linkClickable {
    margin-top: 0px;
    margin-bottom: 0px;
    display: inline-block;
}