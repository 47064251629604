a {
    text-decoration: none;
    color: #000000;
    border-bottom: 3px solid #3286ca;
    transition: 0.2s ease-out; 
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
  }

  .hoverable:hover {
      border-bottom-color: transparent;
  }

  .blue {
    border-bottom: 3px solid #3286ca;
  }

  .pink {
    border-bottom: 3px solid #da12bf;
  }

  .green {
    border-bottom: 3px solid #21ad78;
  }
  
